.radio-buttons-container {
    display: flex;
    flex-direction: column;
    margin: 32px 0px;
}

.radio-buttons-container input[type=radio] {
    display: none;
}

.radio-buttons-container .box {
  background: #ddd;
  width: min(700px, 50vw);
  padding: 12px 22px;
  display: flex;
  border-radius: 10px;
  font-size: 110%;
  border: 2px solid transparent;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-bottom: 20px;
}

.radio-buttons-container .radio-checked {
  background:#F2BD1C;
}

.radio-buttons-container .box:hover {
  background: #F2BD1C;
}

.radio-buttons-container .box .circle {
  height: 20px;
  width: 20px;
  background: #ccc;
  border: 5px solid transparent;
  display: inline-block;
  margin-right: 15px;
  border-radius: 50%;
  transition: all 0.25s ease;
  box-shadow: inset -4px -4px 10px rgba(0, 0, 0, 0.2);
}

.circle-checked {
  height: 20px;
  width: 20px;
  border: 5px solid black;;
  display: inline-block;
  margin-right: 15px;
  border-radius: 50%;
  transition: all 0.25s ease;
  box-shadow: inset -4px -4px 10px rgba(0, 0, 0, 0.2);
  background: #fff;
}

.radio-buttons-container .box .plan {
  display: flex;
  width: 100%;
  align-items: center;
}

.yearly {
  font-family: 'Rubik', sans-serif;
}