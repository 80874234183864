.other-products-container {
    display: flex;
    width: 100vw;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: 200px;
    background-color:white;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    padding-bottom: 100px;
}

.other-products-title-container {
    border-radius: 12px;
    padding: 50px 40px;
    z-index: 4;
}

.other-products-title {
    font-family: 'Poppins';
    font-size: 2.5vw;
    color: #FFFFFF;
}

.other-products-image {
    width: 36vw;
    z-index: 10;
    margin-bottom: -10vw;
    margin-top: -2.5vw;
}

.other-products-text {
    color: #FFFFFF;
    text-transform: uppercase;
    font-size: 2vw;
    font-weight: 600;
    text-align: center;
    z-index: 10;
    margin-top: 80px;
}

.other-products-button-container {
    cursor: pointer;
    margin: 32px 0px;
    background: black;
    box-shadow: 0px 5px 10px #00000017;
    border-radius: 20px;
    opacity: 1;
    padding: 8px 36px;
    transition: .25s;
}

.other-products-button-container:hover {
    transform: scale(1.02);
    filter: brightness(1.05);
}

.other-products-button-container a {
    color: #FFFFFF;
    font-family: "Poppins";
    font-size: 1.8vw;
}

.other-products-disaclaimer-container p {
    color: black;
    font-size: 22px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    text-align: center;
}

.bold {
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
    transition: .15s;
    color: black;
}

.disclaimer-clickable a {
    text-decoration: underline;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
    transition: .15s;
    color: black;
}

.produtos-title{
    width:90%;
    margin: 0 auto;
    display: flex;
}

@media only screen and (max-width: 600px) {
    .other-products-container {
        margin-top: 110px;
    }

    .other-products-title-container {
        padding: 40px 20px;
    }
    
    .other-products-title {
        font-size: 26px;
    }
    
    .other-products-image {
        width: 80vw;
        margin-bottom: -82px;
    }

    .other-products-text {
        font-size: 18px;
        margin-top: 40px;
    }

    .other-products-button-container {
        cursor: pointer;
    }

    .other-products-button-container a {
        font-size: 20px;
    }

    .other-products-disaclaimer-container p {
        color: black;
        font-size: 15px;
        font-family: 'Poppins', sans-serif;
        width: 80vw;
        font-weight: 600;
        text-align: center;
        margin-bottom: -10%;
    }

    .products-image {
        width: 100%;
        margin-top: -10%;
        margin-bottom: -5%;
    }
    
}