@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@100;200;300;400;500;600;700;800;900&family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Barlow Condensed', sans-serif;
}

a {
  text-decoration: none;
}

@font-face {
  font-family: 'Rift Soft Bold';
  src: url('../fonts/poppins/Poppins-Bold.ttf') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rift Soft Demi';
  src: url('../fonts/poppins/Poppins-SemiBold.ttf') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rift Soft Light';
  src: url('../fonts/poppins/Poppins-Light.ttf') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rift Soft Medium';
  src: url('../fonts/poppins/Poppins-Medium.ttf') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rift Soft Regular';
  src: url('../fonts/poppins/Poppins-Regular.ttf') format('woff2');
  font-weight: normal;
  font-style: normal;
}