.home-greet {
    font-size: 30px;
    font-weight: 500;
}

.home-greet-container {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 28px 0px 10px 72px;
}

.greet-toby {
    height: 48px;
    margin-top: -12px;
}

.home-background {
    min-height: calc(100vh - 144px);
    width: 100vw;
    background-color:#EAEAEA;
    overflow-y: hidden;
}

.home-logo-container {
    margin-top: 5vh;
    width: 100vw;
    padding: 30px 50px;
    background-color: black;
    display: flex;
    justify-content: center;
}

.home-logo {
    width: 15%;
}

@media only screen and (max-width: 600px) { 
    .home-greet {
        font-size: 24px;
        font-weight: 500;
        max-width: 64vw;
    }

    .home-greet-container {
        width: fit-content;
        padding: 28px 0px 12px 52px;
    }
    
    .greet-tomada {
        position: absolute;
        left: 5px;
        margin-top: -10px;
        width: 50px
    }

    .home-logo {
        width: 60%;
    }

}