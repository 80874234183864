.footer-container {
    background-color: black;
    display: flex;
    padding: 30px 64px;
    flex-direction: column;
    align-items: center;
    position: absolute;
    margin-top: -44px;
    box-shadow: 0px -4px 18px #00000029;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.footer-up {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.footer-links {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.footer-links a {
    color: #FFFFFF;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 600;
    cursor: pointer;
}

.footer-links p:hover {
    filter: brightness(0.9)
}

.footer-social {
    display: flex;
    gap: 24px
}

.footer-social img {
    width: 30px;
    cursor: pointer;
}

.footer-logo-container {
    width: 180px;
}

.footer-logo {
    cursor: pointer;
    width: 80%;
}

.footer-burocracy {
    margin-top: 30px;;
    padding: 0px 300px;
}

.footer-burocracy p {
    color: #FFFFFF;
    text-align: center;
    font-size: 14px;
    font-family: 'Barlow', sans-serif;
    font-weight: 200;
}

.footer-container-mobile {
    background-color: black;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    display: flex;
    padding: 20px 30px;
    flex-direction: column;
    align-items: center;
    position: absolute;
    margin-top: -44px
}

.footer-links-mobile {
    display: flex;
    gap: 20px;
}

.footer-links-mobile a {
    color: #FFFFFF;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
}

.footer-burocracy-mobile {
    margin-top: 10px;
}

.footer-social-mobile {
    display: flex;
    gap: 24px;
    margin-top: 30px;
}

.footer-social-mobile a img {
    width: 30px;
    cursor: pointer;
}

.footer-burocracy-mobile p {
    color: #FFFFFF;
    text-align: center;
    font-size: 9px;
}

.footer-logo-container-mobile {
    width: 80px;
    margin-top: 32px;
}

.footer-logo-mobile {
    width: 100%;
    cursor: pointer;
}