.promo-card-container {
    width: 20vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    border-radius: 18px;
    background-color: #FFFFFF;
    transition: all .1s ease-out;
    position: relative;
}

.promo-card-join {
    opacity: 1;
    cursor: pointer;
}

.promo-card-join:hover {
    background-color: #F8F8F8;
}

.promo-card-joined {
    opacity: 0.8;
    cursor: default;
    border: 2px solid #50C878;;
}

.promo-card-disabled {
    opacity: 0.6;
    cursor: default;
    border: 1px solid #F5F5F5;
}

.promo-card-title {
    font-size: 2vw;
    font-family: 'Bebas Neue', sans-serif;
    font-weight: 500;
    background: linear-gradient(#651C32 0 0) 0 100% /var(--d, 0) 3px no-repeat;
    transition: 0.5s;
}

.promo-card-join:hover .promo-card-title {
    --d: 100%;
}

.promo-card-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh;
    width: 80%;
    margin: 12px 0px;
}

.promo-card-image {
    width: 70%;
}

.promo-card-description {
    font-size: 1.2vw;
    text-align: center;
}

.promo-card-button {
    padding: 10px 30px;
    border: 0;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 20px;
    font-size: 20px;
    font-weight: 500;
}

.promo-card-container .join {
    color: black;
    background-color: #F2BD1C;
    letter-spacing: 0.7px;
    transition: all .2s ease-out;
}

.promo-card-container:hover .join {
    transform: scale(1.06);
    filter: brightness(1.2)
}

.joined {
    color: #FFFFFF;
    background-color: #50C878;
    letter-spacing: 0.7px;
    transition: all .2s ease-out;
    cursor: default;
}

.promo-card-new-alert {
    position: absolute;
    top: -8px;
    right: -22px;
    background-color: #F2BD1C;
    color: black;
    padding: 6px 12px;
    border-radius: 12px;
    font-weight: 600;
}

@media only screen and (max-width: 600px) {
    .promo-card-container {
        width: 80vw;
    }

    .promo-card-title {
        font-size: 7vw;
    }

    .promo-card-description {
        font-size: 4vw;
    }
    
    .promo-card-image-container {
        height: 132px;
        width: 90%;
        margin: 18px 0px;
    }

}