.title-on-layer-2 {
    border-radius: 22px;
    padding: 5px;
    background-color: transparent;
    border: 4px solid black;
    z-index: 4;
}


.title-on-layer-1 {
    background-color: black;
    padding: 0.6vw 2vw;
    color: #FFFFFF;
    border-radius: 15px;
    z-index: 4;
}

.title-on-text {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 2vw;
    letter-spacing: 0.6px;
    z-index: 4;
}

@media (max-width: 600px) {
    
    .title-on-layer-1 {
        background-color: black;
        padding: 2.5vw 6vw;
        color: #FFFFFF;
        border-radius: 15px
    }
    
    .title-on-text {
        font-family: 'Poppins';
        font-size: 5.2vw;
        letter-spacing: 0.6px;
    }
}