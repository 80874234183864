.home-cards-container {
    background-color: black;
    padding: 10px 350px 60px 350px;
    display: flex;
    gap: 32px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: -1px;
}

@media only screen and (max-width: 600px) { 
    .home-cards-container {
        gap: 20px;
        padding: 10px 0px 60px 0px;
    }
}