.numbers-background {
    min-height: calc(100vh - 100px);
    width: 100vw;
    background-color:#EAEAEA;
    padding: 64px 160px;
    overflow-x: hidden;
}

.numbers-table-container {
    padding: 42px 64px;
    border-radius: 20px;
    background-color: #FFFFFF;
    z-index: 100 !important;
    position: relative;
}

.numbers-table-title {
    font-size: 42px;
    font-family: 'Poppins';
    font-weight: 600;
    width: 600px;
    color: #FFFFFF;
    background-color: #651c32;
    padding: 10px 20px;
    border-radius: 20px;
}

.numbers-table-title-select {
    display: flex;
    gap: 60px;
    height: 60px;
    align-items: center;
    justify-content: center;
    margin-bottom: 58px;
}

.numbers-table-filters-container {
    display: flex;
    border-bottom: 1px solid #cfcfc4;
    font-size: 1.32vw;
    font-weight: 500;
    gap: 52px;
    margin-bottom: 32px;
}

.numbers-table-filter-selected {
    display: flex;
    border-bottom: 1px solid black;
    padding: 16px 20px;
    font-weight: 600;
    cursor: pointer;
}

.numbers-table-filter {
    display: flex;
    padding: 16px 20px;
    cursor: pointer;
    opacity: 0.5;
}

.numbers-table-filter-quantity {
    background-color: rgba(207,207,196, 0.6);
    border-radius: 50%;
    font-size: 12px;
    margin-left: 16px;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.numbers-table-filter-quantity-selected {
    background-color: rgba(207, 207, 196, 1);
    border-radius: 50%;
    font-size: 12px;
    margin-left: 16px;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.numbers-table-filter-quantity-selected-red {
    background-color: rgba(218, 41, 28, 0.25);
    border-radius: 50%;
    font-size: 10px;
    margin-left: 16px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.numbers-table-filter-quantity-selected-green {
    background-color: rgba(119, 221, 118, 0.3);
    border-radius: 50%;
    font-size: 10px;
    margin-left: 16px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.numbers-table-search-container {
    display: flex;
    align-items: center;
    margin-bottom: 22px;
    justify-content: space-between;
    width: 708px;
}

.numbers-table-sort-icon {
    width: 20px;
    cursor: pointer;
    margin-left: 16px;
    margin-bottom: -4px
}

.numbers-table-pagination-container {
    display: flex;
    gap: 14px;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 300;
    margin-left: 24px;
}

.numbers-table-rows-per-page {
    width: 200px;
    padding: 5px;
    font-size: 14px;
    border: 1px solid #cfcfc4;
    border-radius: 4px;
    margin-right: 24px;
}

.numbers-table-rows-per-page option {
    padding: 20px;
}

.numbers-table-arrow-icon {
    width: 25px;
    background-color: #cfcfc4;
    padding: 6px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 20px;
}

.numbers-table-arrow-reverse-icon {
    rotate: 180deg;
    width: 25px;
    background-color: #cfcfc4;
    padding: 6px;
    border-radius: 4px;
    cursor: pointer;
}

.numbers-table-arrow-icon-disabled {
    opacity: 0.3;
    cursor: default;
}

.numbers-table-page {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
}

.numbers-table {
    border-spacing: 0;
}

.numbers-table th {
    padding: 20px 32px;
    font-size: 1.2vw;
    font-weight: 600;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
}

.numbers-table td {
    padding: 18px 38px;
    text-align: center;
}

.numbers-table tr {
    border-radius: 10px;
    font-size: 1.025vw;
    letter-spacing: 0.5px;
}

.numbers-table tbody tr:hover {
    background-color: #fbfbfb;
}

.numbers-table-luck-number {
    font-size: 1.025vw;
    transition: .2s;
}

.numbers-table-luck-number:hover {
    transform: scale(1.025);
}

td:first-child {
    border-top-left-radius: 10px; 
    border-bottom-left-radius: 10px;
}
td:last-child {
    border-bottom-right-radius: 10px; 
    border-top-right-radius: 10px; 
}

.numbers-table-numeration {
    opacity: 0.3;
    width: 120px;
    padding: 0px;
}


@media only screen and (max-width: 600px) {
    .numbers-background {
        padding: 42px 16px;
        overflow-x: hidden;
    }

    .numbers-table-container {
        padding: 24px 16px;
        border-radius: 20px;
        background-color: #FFFFFF;
        width: fit-content;
        z-index: 10;
    }

    .numbers-table-title {
        font-size: 1.8rem;
    }

    .numbers-table-title-select {
        flex-direction: column;
        gap: 20px;
        height: 76px;
        margin-bottom: 22px;
        margin-top: 20px;
        justify-content: center;
        align-items: center;
    }

    .numbers-table th {
        padding: 10px 8px;
        font-size: 4.2vw;
        font-weight: 500;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    
    .numbers-table td {
        padding: 12px 8px;
        text-align: center;
        font-size: 4vw;
    }

    .numbers-table-numeration {
        width: 20px;
    }

    .numbers-table-filters-container {
        font-size: 1.2rem;
        gap: 22px;
        justify-content: center;
    }

    .numbers-table-search-container {
        flex-direction: column;
        justify-content: flex-start;
        align-items: baseline;
        gap: 20px;
        margin-left: 10px;
        width: 60vw;
    }

    .numbers-table-search {
        margin-left: 0px;
    }

    .numbers-table-filter-selected {
        padding: 10px 4px;
    }
    
    .numbers-table-filter {
        padding: 10px 4px;
    }

    .numbers-table-filter-quantity {
        display: none;
    }
    
    .numbers-table-filter-quantity-selected {
        display: none;
    }
    
    .numbers-table-filter-quantity-selected-red {
        display: none;
    }
    
    .numbers-table-filter-quantity-selected-green {
        display: none;
    }

    .numbers-table-pagination-container {
        gap: 10px;
        margin-left: 2px;
    }
    
    .numbers-table {
        width: calc(100vw - 62px);
    }

    .numbers-table-sort-icon {
        width: 16px;
        margin-left: 12px;
    }
}