.profile-background {
    min-height: calc(100vh - 144px);
    width: 100vw;
    background-color:#EAEAEA;
    padding: 52px 100px;
    display: flex;
    gap: 2vw;
}

@media (max-width: 600px) {
    .profile-background {
        flex-direction: column;
        padding: 42px 20px;
        gap: 32px;
    }

    .profile-card {
        width: 100%;
    }
    
    .address-card {
        width: 100%;
    }

    .profile-form-uneditable {
        flex-direction: column;
    }

    .profile-form-row {
        flex-direction: column;
        
    }

    .profile-form-input-container {
        width: 100%;
    }

    .profile-form-input-container-disabled {
        width: 100%;
    }

    .profile-form-buttons-container {
        display: flex;
        gap: 32px;
        margin-bottom: 10px;
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    .profile-form-input-container-80 {
        width: 100%;
    }
    
    .profile-form-input-container-20 {
        width: 100%;
    }
    
    .profile-form-input-container-disabled {
        width: 100%;
    }

    .address-form-cep-container {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .profile-form-cancel-button {
        padding: 10px 6vw;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        font-size: 5vw;
        background-color: #D3D3D3;
        cursor: pointer;
        border: 1px solid #D3D3D3;
    }
    
    .profile-form-save-button {
        padding: 10px 6vw;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        font-size: 5vw;
        background-color: #DA291C;
        cursor: pointer;
        border: 1px solid #DA291C;
        color: #FFFFFF;
        font-weight: 500;
        transition: all 0.2s ease-in-out;
}
}