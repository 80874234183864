.prize-form-background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    padding: 90px 0px 60px 0px;
    overflow-x: hidden;    
}

.prize-form-container {
    position: relative;
    width: 42vw;
    height: fit-content;
    background-color: #FFFFFF;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 36px 52px;
    z-index: 500;
}

.prize-form-close {
    position: absolute;
    width: 20px;
    right: 0;
    cursor: pointer;
    margin: -10px 20px 0px 0px;
}

.prize-form-close:hover {
    filter: brightness(0.8)
}


.prize-form-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
}

.prize-form-title {
    width: 100%;
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    padding-bottom: 20px;
    margin-bottom: 22px;
    border-bottom: 1px solid #D3D3D3;
    font-family: 'Poppins';
}

.prize-form-subtitle {
    width: 100%;
    text-align: center;
    font-size: 20px;
    margin-bottom: 32px;
    padding-bottom: 20px;
    border-bottom: 1px solid #D3D3D3;
}

.prize-form-input-container-100 {
    width: 100%
}

.prize-form-input-container-80 {
    width: 76%
}

.prize-form-input-container-20 {
    width: 22%
}

.prize-form-input-container-50 {
    width: 49%
}

.prize-form-input {
    width: 100%;
    height: 42px;
    border-radius: 10px;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    outline: none;
    border: 1px solid #D3D3D3;
    letter-spacing: 0.6px;
}

.prize-form-input-label {
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 3px;
}

.prize-form-submit-button {
    padding: 8px 28px;
    border: 0;
    background-color: #FFFFFF;
    border: 1px solid red;
    border-radius: 6px;
    color: red;
    cursor: pointer;
    transition: all 0.2s ease-out;
    font-size: 22px;
    letter-spacing: 0.6px;
    font-weight: 600;
    margin-top: 32px;
}

.prize-form-submit-button:hover {
    background-color: red;
    border: 1px solid red;
    color: #FFFFFF;
    transform: scale(1.05);
}

.prize-form-separator {
    height: 1px;
    width: 100%;
    background-color: #D3D3D3;
    margin: 20px 0px 15px 0px;
}

.files-form-title-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.files-form-title {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 1.35vw;
    margin-bottom: 20px;
}

.file-input-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 16px;
}

@media (max-width: 600px) {
    .prize-form-background {
        height: fit-content;
        padding: 40px 0px;
    }

    .prize-form-container {
        width: 92vw;
        padding: 20px 20px;
        overflow: auto;
    }

    .prize-form-close {
        position: absolute;
        width: 20px;
        right: 0;
        cursor: pointer;
        margin: 0px 20px 0px 0px;
    }    

    .prize-form-title {
        font-size: 28px;
    }

    .files-form-title {
        font-size: 24px;
    }
}