.aceleradores-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.aceleradores-border {
    position: absolute;
    border: 5px solid #D60812;
    border-radius: 20px;
    height: calc(340px + 42vw);
    width: 90vw;
    top: calc(1vw + 74px);
    z-index: 1;
}

.aceleradores-title-container {
    padding: 20px 0px;
    border-radius: 24px;
    position: relative;
    z-index: 3;
    margin-top: -5%;
}

.aceleradores-title {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 2.8vw;
    color: #FFFFFF;
    text-align: center;
}

.aceleradores-subtitle {
    font-size: 2vw;
    color: black;
    font-weight: 600;
    margin: 12px 0px 30px 0px;
}

.toby-acelerador {
    position: absolute;
    width: 80px;
    left: 20px;
    bottom: 0;
}

.aceleradores-info-container {
    background-color: #D60812;
    border-radius: 10px;
    padding: 12px 32px 12px 140px;
    margin-bottom: 32px;
    position: relative;
}

.aceleradores-info-text {
    font-weight: 500;
    font-size: 1.6vw;
    color: #FFFFFF;
    letter-spacing: 0.2px;
    text-align: center;
}

.aceleradores-all-container {
    display: flex;
    flex-wrap: wrap;
    padding: 60px 250px;
    justify-content: center;
    align-items: center;
    gap: 60px;
}

.aceleradores-produtos-container {
    background-color: #651C32;
    border-radius: 12px;
    width: 15vw;
    height: calc(25vw * 0.559);
    margin: 20px 100px;
    position: relative;
    display: flex;
    justify-content: center;
}

.aceleradores-image {
    width: 80%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
}

.aceleradores-description-container {
    background: transparent;
    position: absolute;
    bottom: -56px;
    left: 50%;
    transform: translate(-50%,0);
    z-index: 10;
    min-width: 150%;
}

.aceleradores-description {
    color: black;
    font-size: 34px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0px;
}

.plus-five {
    background-color: #D60812;
    width: fit-content;
    height: fit-content;
    padding: 8px 20px 8px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-size: 64px;
    font-weight: 700;
    z-index: 40;
    border-radius: 22px;
    position: absolute;
    bottom: -16px;
    right: -32px;
}

#button-products{
    margin-top: 0%;
    margin-bottom: -6%;
    z-index: 100;
}


@media only screen and (max-width: 600px) {
    .aceleradores-title-container {
        padding: z0px 12px;
        border-radius: 12px;
        margin-bottom: -15%;
    }

    .aceleradores-border {
        height: calc(340px + 190vw + 10px);
        width: 94vw;
        top: calc(16px + 3.25vw + 48px);
    }
    
    .aceleradores-image {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }
    
    .aceleradores-title {
        font-size: 25px;
    }
    
    .aceleradores-subtitle {
        display: none;
    }

    .toby-acelerador {
        width: 50px;
        left: 6px;
    }
    
    .aceleradores-info-container {
        background-color: #D60812;
        border-radius: 10px;
        padding: 12px 12px 12px 66px;
        margin-bottom: 18px;
        position: relative;
    }
    
    .aceleradores-info-text {
        font-size: 16.5px;
        letter-spacing: 0.5px;
    }

    .aceleradores-all-container {
        gap: 10px;
        padding: 0px 200px;
    }

    .aceleradores-produtos-container {
        background-color: #651C32;
        border-radius: 12px;
        width: 82vw;
        height: calc(82vw * 0.559);
        margin: 20px 0px;
        position: relative;
        display: flex;
        justify-content: center;
    }
    
    .aceleradores-description-container {
        background: transparent linear-gradient(180deg, #D60812 0%, #651C32 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 13px 14px #00000029;
        border: 1px solid black;
        border-radius: 5px;
        backdrop-filter: blur(4px);
        padding: 5px 2px;
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translate(-50%,0);
        min-width: 104%;
        z-index: 10;
    }

    .aceleradores-description {
        color: #FFFFFF;
        font-size: 20px;
        font-weight: 400;
        text-align: center;
        letter-spacing: 0px;
        text-transform: none;
    }

    .plus-five {
        display: none;
    }

    #button-products{
        margin-top: 10%;
        margin-bottom: -20%;
        z-index: 100;
    }
}