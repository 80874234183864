.file-input-container .file-input-label {
    font-size: 18px;
    font-weight: 600;
}

.file-input {
    padding: 2px;
    border: 1px solid #B0B0B0;
    border-radius: 6px;
    border-style: dotted;
}

.file-input-container input[type="file"]::before {
    top: 8px;
}
  
.file-input-container input[type="file"]::before {
    position: absolute;
    pointer-events: none;
    left: 52px;
    color: #DA291C;
    content: "Escolha um arquivo";
    font-size: 18px;
    font-weight: 500;
}
  
.file-input-container input[type="file"] {
    position: relative;
    font-size: 16px;
    width: 440px;
    color: #707070;
}
  
.file-input-container input[type="file"]::file-selector-button {
    width: 220px;
    color: transparent;
}

.file-input-container input[type="file"]::after {
    position: absolute;
    pointer-events: none;
    left: 22px;
    top: 10px;
    height: 17px;
    width: 17px;
    content: "";
    background-image: url('../../assets/icons/red-upload.png');
    background-size: cover;
}
  
.file-input-container input[type="file"]::file-selector-button {
    border-radius: 6px;
    padding: 0 6px;
    height: 36px;
    cursor: pointer;
    background-color: white;
    border: 1px solid #B0B0B0;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    margin-right: 16px;
    transition: background-color 200ms;
}

.file-input-warning {
    color: #FF5F5F;
    height: 20px;
    font-size: 15px;
    margin-top: -4px;
}

@media (max-width: 600px) {
    .file-input-container input[type="file"]::before {
        content: "Arquivo";
        font-size: 18px;
    }
      
    .file-input-container input[type="file"] {
        width: 80vw;
    }
    
    .file-input-container input[type="file"]::file-selector-button {
        width: 120px;
    }
}