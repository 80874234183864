.home-banner-container {
    width: 100vw;
    height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-banner-page-container {
    position: relative;
    padding: 10px 10px 0px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%; 
}

.home-banner-page {
    height: 100%;
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
}

.home-banner-img {
    height: 80%;
    padding-bottom: 10px;
}

.home-banner-cta-container {
    background-color: #F2BD1C;
    height: 8%;
    border-radius: 32px;
    padding: 0px 30px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.35s ease-out;
    position: absolute;
    bottom: 55px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.home-banner-cta-container-final {
    bottom: 39px;
}

.home-banner-cta-container-welcome {
    bottom: 35px;
}

.home-banner-cta-container-winner {
    bottom: 110px;
}

.home-banner-cta-container-winner p {
    font-size: 25px;
}

.home-banner-cta-container:hover {
    filter: brightness(1.2);
    transform: scale(1.03);
}

.home-banner-cta-text {
    font-family: 'Poppins';
    font-weight: 600;
    text-transform: uppercase;
    color: black;
    font-size: 22px;
}

.swiper-button-next,
.swiper-button-prev {
    color: #FFFFFF;
}

.swiper-button-next {
    background-image: url('../../../assets/banner/next.svg');
    background-repeat: no-repeat;
    background-size: 70% auto;
    background-position: center;
    margin-right: 100px;
}

.swiper-button-next::after {
    display: none;
}

.swiper-button-prev {
    background-image: url('../../../assets/banner/prev.svg');
    background-repeat: no-repeat;
    background-size: 70% auto;
    background-position: center;
    margin-left: 100px;
}

.swiper-button-prev::after {
    display: none;
}

.swiper-pagination-bullet-active {
    background-color: #FFFFFF !important;
    transform: scale(1.3);
    transition: .3s;
}

#welcome-img {
    width: 20%;
    height: auto;
    padding-top: 2%;
}

#final-img {
    margin-top: 20px;
}

#semanal-img {
    margin-top: 25px;
    width: 20%;
    height: auto;
}

#mensal-img {
    margin-top: 25px;
    width: 20%;
    height: auto;
}

@media only screen and (max-width: 1280px) {
    #welcome-img {
        width: 30%;
        height: auto;
        padding-top: 2%;
    }
}

@media only screen and (max-width: 600px) {
    #welcome-img {
        width: 90%;
        height: auto;
        margin-top: 10px;
    }

    #final-img {
        width: 120%;
        margin-top: 8px;
    }

    #semanal-img {
        width: 65%;
        margin-top: 25px;
    }

    #mensal-img {
        margin-top: 25px;
        width: 70%;
        height: auto;
    }

    .home-banner-img {
        width: 100%;
        height: auto;
        padding-bottom: 10px;
    }

    .home-banner-cta-container {
        padding: 0px 20px;
        bottom: calc(312px - 70vw);
    }

    .home-banner-cta-container-welcome {
        bottom: calc(311px - 65vw);
    }

    .home-banner-cta-container-winner {
        bottom: calc(359px - 60vw);
    }

    .home-banner-cta-container-winner p {
        font-size: 21px;
    }

    .home-banner-cta-text {
        font-size: 20px;
    }

    .home-banner-container .swiper-button-next {
        margin-right: 10px;
        background-size: 0% auto;
    }

    .home-banner-container .swiper-button-prev {
        margin-left: 10px;
        background-size: 0% auto;
    }

    .home-banner-page-container {
        padding: 10px 0px 0px 0px;
    }

    .home-banner-container {
        width: 100vw;
        height: 390px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .home-banner-page {
        border-radius: 0px;
    }
}
