.phone-number-confirmation-container {
    width: 100%;
    min-height: calc(100vh);
    background-color: #EAEAEA;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: calc(82px + 2vw) 0px 4vw 0px;
    flex-direction: column;
    gap: 2.5vh;
}

.phone-number-card {
    display: flex;
    border-radius: 24px;
    height: fit-content;
    position: relative;
    border: 10px solid black;
    background-color: black;
    background-image:
    radial-gradient(at 51% 100%, hsla(60, 59%, 78%, 0.12) 0px, transparent 50%),
    radial-gradient(at 51% 0%, hsla(0, 0%, 97%, 0.05) 0px, transparent 50%),
    radial-gradient(at 51% 0%, hsla(60, 59%, 78%, 0.4) 0px, transparent 50%);
}

.phone-number-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 32vw;
    height: fit-content;
    padding: 30px 0px;
    border-radius: 28px;
}

.phone-number-form-title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 2.4vw;
    text-align: center;
    color: #FFFFFF;
}

.phone-number-form-subtitle {
    font-size: 1.35vw;
    font-weight: 400;
    text-align: center;
    color: #FFFFFF;
    margin: -2px 0px 24px 0px;
}

.phone-number-form-text {
    font-size: 1.35vw;
    font-weight: 400;
    text-align: center;
    color: #FFFFFF;
}

.phone-number-form-button {
    background-color: #00C679;
    border: 3px solid #FFFFFF;
    border-radius: 24px;
    padding: 10px 20px;
    font-size: 1.5vw;
    font-weight: 700;
    text-transform: uppercase;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.35s ease-out;
    margin-top: 12px;
    font-family: 'Poppins';
    width: auto;
    height: 56px;
    line-height: 1;
}

.phone-number-form-button:hover {
    transform: scale(1.05);
    filter: brightness(1.1);
}

@media (max-width: 600px) {
    .phone-number-confirmation-container {
        padding: calc(82px + 10vw) 0px 15vw 0px;
    }

    .phone-number-card {
        width: 90vw;
        height: fit-content;
        position: relative;
        border: none;
        flex-direction: column;
    }

    .phone-number-form-container {
        width: 90vw;
    }

    .phone-number-form-title {
        font-size: 9vw;
    }

    .phone-number-form-subtitle {
        font-size: 5.2vw;
        margin-top: 0px;
    }

    .phone-number-form-button {
        font-size: 7vw;
    }

    .phone-number-form-text {
        font-size: 5vw;
    }
}
