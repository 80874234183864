.new-email-container {
    width: 100%;
    min-height: calc(100vh);
    background-color:#EAEAEA;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: calc(82px + 2vw) 0px 4vw 0px;
    display: flex;
    flex-direction: column;
    gap: 2.5vh;
}

#text-email{
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
}

.color-mail{
    font-weight: 700;
}

.back-login{
    font-size: 25px;
    font-weight: 700;
    color: white;
    cursor: pointer;
    text-decoration: underline;

}

.custom-error-class {
    font-size: 1.35vw;
    font-weight: 400;
    text-align: center;
    color: #FFFFFF;
}

.new-email-card {
    display: flex;
    border-radius: 24px;
    height: fit-content;
    position: relative;
    border: 10px solid black;
    background-color:black;
    background-image:
    radial-gradient(at 51% 100%, hsla(60,59%,78%,0.12) 0px, transparent 50%),
    radial-gradient(at 51% 0%, hsla(0,0%,97%,0.05) 0px, transparent 50%),
    radial-gradient(at 51% 0%, hsla(60,59%,78%,0.4) 0px, transparent 50%);
}

.new-email-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 32vw;
    height: fit-content;
    padding: 30px 0px;
    border-radius: 28px;
}


.new-email-form-title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 2.4vw;
    text-align: center;
    color: #FFFFFF;
}

.new-email-form-subtitle {
    font-size: 1.35vw;
    font-weight: 400;
    text-align: center;
    color: #FFFFFF;
    margin: -2px 0px 24px 0px;
}

.new-email-form-text{
    font-size: 1.35vw;
    font-weight: 400;
    text-align: center;
    color: #FFFFFF;
}

.new-email-form-inputs-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 12%;
    gap: 4px;
}

.response-email{
    font-size: 30px;
}

.new-email-form-button {
    background-color: #00C679;
    border: 3px solid #FFFFFF;
    border-radius: 24px;
    padding: 10px 20px;
    font-size: 1.5vw;
    font-weight: 700;
    text-transform: uppercase;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.35s ease-out;
    margin-top: 12px;
    font-family: 'Poppins';
    width: auto;
    height: 56px;
    line-height: 1;
}

.new-email-form-button:hover {
    transform: scale(1.05);
    filter: brightness(1.1)
}

@media (max-width: 600px) {
    .new-email-container {
        padding: calc(82px + 10vw) 0px 15vw 0px;
    }

    .new-email-card {
        width: 90vw;
        height: fit-content;
        position: relative;
        border: none;
        flex-direction: column;
    }

    .new-email-form-container {
        width: 90vw;
    }

    .new-email-form-title {
        font-size: 9vw;
    }

    .new-email-form-subtitle {
        font-size: 5.2vw;
        margin-top: 0px;
    }

    .new-email-form-button {
        font-size: 7vw;
    }
}