@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

.admin-home-background {
    background-color: #F0F0F1;
    width: 100vw;
    height: 100vh;
    display: flex;
    overflow-x: hidden;
}

.admin-page-container {
    padding: 60px 80px 0px calc(20vw + 120px);
    width: 100%;
    overflow-x: hidden;
}

.admin-page-title-container {
    display: flex;
    align-items: center;
    gap: 64px;
    margin-bottom: 8vh;
}

.admin-page-title {
    font-size: 2.2vw;
    font-weight: 600;
    letter-spacing: 0.6px;
    font-family: 'Rubik', sans-serif;
}

.admin-page-primary-button {
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
    height: 48px;
    border: 2px solid black;
    border-radius: 10px;
    color: black;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.15s ease-out;
    padding: 0px 32px;
}

.admin-page-primary-button:hover {
    color: black;
    background-color: #F2BD1C;
}

.admin-page-secundary-button {
    border: 0;
    color: black;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.15s ease-out;
    margin-top: 72px;
    font-family: 'Rubik', sans-serif;
    background: linear-gradient(90deg, black calc(100%/3), transparent 0 calc(200%/3), black 0) var(--d, 0%) 100% /300% 1.5px no-repeat;
    transition: 0.25s;
}

.button-bold {
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
}

.admin-page-secundary-button:hover {
    --d: 100%;
    transition: 0.25s;
    transform: scale(1.01);
    filter: brightness(1.05)
}

.admin-page-download-button {
    border: 0;
    color: #DA291C;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.15s ease-out;
    font-family: 'Rubik', sans-serif;
    background: linear-gradient(90deg, #DA291C calc(100%/3), transparent 0 calc(200%/3), #DA291C 0) var(--d, 0%) 100% /300% 1.5px no-repeat;
    transition: 0.25s;
    margin-right: 12px;
}

.admin-page-download-button:hover {
    --d: 100%;
    transition: 0.25s;
    transform: scale(1.01);
    filter: brightness(1.05)
}

.admin-draws-table-title-container {
    display: flex;
    gap: 16px;
    margin-bottom: 12px;
}

.admin-draw-table-title {
    font-family: 'Rubik', sans-serif;
    font-size: 1.35vw;
    font-weight: 600;
}

.add-row-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    transition: .25s ease-out;
}

.add-row-icon-disabled {
    width: 24px;
    height: 24px;
    cursor: default;
    opacity: 0.5;
}

.add-row-icon:hover {
    filter: brightness(2);
}

.admin-draws-tables-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.admin-draw-table-warning {
    color: #FF5F5F;
    font-family: "Rubik", sans-serif;
}

.admin-page-register-form-container {
    width: 30vw;
}

.admin-page-button-container {
    display: flex;
    gap: 40px;
    align-items: center;
}

.admin-page-export-container {
    display: flex;
    gap: 40px;
    align-items: end;
}

.admin-page-error-import {
    color: #FF5F5F;
    font-family: "Rubik", sans-serif;
    margin-top: 1px;
}

.admin-page-success-import {
    color: #5cb85c;
    font-family: "Rubik", sans-serif;
    margin-top: 1px;
}

.admin-page-select-input-container {
    width: 100%;
    display: flex;
    gap: 20px;
    align-items: center;
}

.admin-page-error-fetching {
    color: #FF5F5F;
    font-family: "Rubik", sans-serif;
}

.admin-page-search-container {
    background-color: #651C32;
    padding: 8px 14px;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.25s;
}

.admin-page-search-container:hover {
    filter: brightness(1.25);
}

.admin-page-search-container p {
    color: #FFFFFF;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    font-size: 18px;
}

.admin-page-save-container {
    background-color: #DA291C;
    padding: 8px 14px;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.25s;
}

.admin-page-save-container-disabled {
    opacity: 0.4;
    cursor: default;
}

.admin-page-save-container p {
    color: #FFFFFF;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    font-size: 18px;
}

.search-button{
    width:80px;
    padding: 12px 15px 12px 15px;
    border-radius: 20px;
    font-size: 1vw;
    border: 1px solid #D3D3D3;
    letter-spacing: 0.6px;
    background-color: white;
    cursor: pointer;
}