.profile-card {
    width: calc(48vw - 104px);
    height: fit-content;
    background-color: #FFFFFF;
    border-radius: 18px;
    padding: 12px 32px;
}

.profile-form {
    margin-top: 10px;
}

.profile-upper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
}

.profile-title {
    font-size: 36px;
    font-weight: 600;
    font-family: 'Poppins';
}

.profile-edit-icon {
    height: 30px;
    cursor: pointer;
}

.profile-edit-icon:hover {
    opacity: 0.6;
}

.profile-form-line {
    width: 100%;
    height: 1px;
    background-color: #D3D3D3;
    margin: 20px 0px 32px 0px;
}

.profile-form-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 24px;
}

.profile-form-buttons-container {
    display: flex;
    gap: 32px;
    margin-bottom: 10px;
    flex-direction: row-reverse;
}

.profile-form-cancel-button {
    padding: 10px 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-size: 1.2rem;
    background-color: #D3D3D3;
    cursor: pointer;
    border: 1px solid #D3D3D3;
}

.profile-form-save-button {
    padding: 10px 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-size: 1.2rem;
    background-color: #DA291C;
    cursor: pointer;
    border: 1px solid #DA291C;
    color: #FFFFFF;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
}

.profile-form-save-button:hover {
    background-color: #FFFFFF;
    color: #DA291C;
}

@media (max-width: 600px) {    
    .profile-title {
        font-size: 30px
    }

    .profile-edit-icon {
        height: 24px
    }

    .profile-card {
        width: 100%;
    }
    
    .address-card {
        width: 100%;
    }

    .profile-form-uneditable {
        flex-direction: column;
    }

    .profile-form-row {
        flex-direction: column;
        
    }

    .profile-form-input-container {
        width: 100%;
    }

    .profile-form-input-container-disabled {
        width: 100%;
    }

    .profile-form-buttons-container {
        display: flex;
        gap: 32px;
        margin-bottom: 10px;
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    .profile-form-input-container-80 {
        width: 100%;
    }
    
    .profile-form-input-container-20 {
        width: 100%;
    }
    
    .profile-form-input-container-disabled {
        width: 100%;
    }

    .address-form-cep-container {
        width: 70%;
        display: flex;
        align-items: center;
    }

    .profile-form-cancel-button {
        padding: 10px 6vw;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        font-size: 5vw;
        background-color: #D3D3D3;
        cursor: pointer;
        border: 1px solid #D3D3D3;
    }
    
    .profile-form-save-button {
        padding: 10px 6vw;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        font-size: 5vw;
        background-color: #DA291C;
        cursor: pointer;
        border: 1px solid #DA291C;
        color: #FFFFFF;
        font-weight: 500;
        transition: all 0.2s ease-in-out;
}
}