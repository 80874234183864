.navbar-container {
    width: 100vw;
    height: 88px;
    background-color: black;
    padding: 22px 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 100;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    box-shadow: 0px 3px 6px #00000029;
}

.only-desktop {
    display: flex;
}

.only-mobile {
    display: none;
}

.navbar-logo-container {
    height: 100%;
    display: flex;
    gap: 28px;
    align-items: center;
}

.navbar-femsa-logo-image, 
.navbar-juntos-logo-image {
    height: 120%;
}

.navbar-right-container {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 42px;
}

.navbar-signin-container {
    padding: 8px 20px;
    border-radius: 0px 16px 16px 16px;
    color: #FFFFFF;
    background-color: #F2BD1B;
    letter-spacing: 0.8px;
    cursor: pointer;
    transition: all 0.2s ease-out;
}

.navbar-signin-container p,
.navbar-login-container p {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 20px;
}

.navbar-signin-container:hover {
    color: black;
    background-color: #FFFFFF;
}

.navbar-login-container {
    padding: 8px 20px;
    border: 1px solid black;
    border-radius: 0px 16px 16px 16px;
    color: #FFFFFF;
    font-size: 18px;
    letter-spacing: 0.8px;
    cursor: pointer;
    transition: all 0.2s ease-out;
}

.navbar-login-container:hover {
    border: 1px solid #FFFFFF
}

.navbar-social-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    height: 80%;
    margin-left: 36px;
}

.navbar-social-icon {
    height: 90%;
    cursor: pointer;
}

.navbar-menu-nav-item {
    color: black;
    font-size: 18px;
    padding: 4px 10px;
    font-weight: 500;
    cursor: pointer;
    letter-spacing: 0.4px;
    background: linear-gradient(currentColor 0 0) bottom /var(--d, 0) 2px no-repeat;
    transition: 0.3s;
}


@media only screen and (max-width: 600px) {
    .navbar-container {
        padding: 24px;
        height: 88px;
    }

    .navbar-femsa-logo-image {
        display: none;
    }

    .navbar-social-container {
        display: none;
    }

    .navbar-nav-container {
        display: none;
    }

    .navbar-login-container {
        display: none;
    }   

    .navbar-signin-container {
        padding: 8px 12px;
    }

    .only-desktop {
        display: none;
    }
    
    .only-mobile {
        display: flex;
    }
    
}