.admin-login-background {
    background-color: #F0F0F1;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-login-container {
    gap: 32px;
    width: 30vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin-login-logo-container {
    height: 12vh;
}

.admin-login-logo-image {
    height: 100%;
}

.admin-login-inputs-container {
    width: 100%;
    height: 300px;
    background-color: #FFFFFF;
    border: 1px solid #B3B3B3;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 32px 52px;
}

.admin-login-return-container {
    width: 100%;
    font-size: 1.2vw;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.admin-login-return-container p {
    font-family: 'Rubik', sans-serif;
}

.admin-login-button {
    border-radius: 8px;
    border: 0;
    background-color: #EC2227;
    color: #FFFFFF;
    cursor: pointer;
    font-size: 22px;
    align-self: flex-end;
    font-family: 'Poppins';
    font-weight: 600;
    width: 120px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-login-button:hover {
    filter: brightness(0.95);
}

.admin-login-back-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}
