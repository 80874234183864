.topbar-container {
  width: 100vw;
  height: 144px;
  background-color: black;
  padding: 18px 100px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  box-shadow: 0px 3px 6px #00000029;
}
  
.topbar-upper-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
  
.topbar-logo-container,
.topbar-logo-image {
  display: flex;
  height: 65px;
  cursor: pointer;
  gap: 34px;
  align-items: center;
}

.topbar-logo-image-promo {
  display: flex;
  height: 130%;
  cursor: pointer;
  gap: 32px;
}

.topbar-participants-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  color: #ffffff;
  font-size: 18px;
}
  
.topbar-participants-number {
  font-weight: 700;
  font-size: 28px;
  margin-bottom: -2px;
}
  
.topbar-profile-container {
  width: 320px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topbar-profile {
  display: flex;
  align-items: center;
  gap: 18px;
  cursor: pointer;
}
  
.topbar-profile-icon {
  height: 26px;
  cursor: pointer;
}
  
.topbar-menu-profile-icon {
  height: 26px;
  cursor: pointer;
}
  
.topbar-profile-greet {
  font-size: 24px;
  color: #ffffff;
  background: linear-gradient(#ffffff 0 0) 0 100% /var(--d, 0) 2px no-repeat;
  transition: 0.5s;
}

.topbar-profile-greet:hover {
  --d: 100%;
}
  
.topbar-profile-name {
  font-weight: 500;
}
  
.topbar-nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 12px;
  height: 42px;
  padding: 0px min(8vw, 100px);
  letter-spacing: 0.8px;
}
  
.topbar-nav-item {
  color: #ffffff;
  font-size: 20px;
  padding: 6px 18px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
}
  
.topbar-nav-item:hover {
  opacity: 0.8;
}
  
.topbar-nav-item-selected {
  color: black;
  font-size: 20px;
  font-weight: 700;
  background-color: #F2BD1C;
  padding: 6px 18px;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
}
  
.topbar-exclamation-icon {
  width: 16px;
  right: -5px;
  top: -5px;
  position: absolute;
  transition: transform 1s;
  animation: pulse 2s infinite;
}

  @media (max-width: 600px) {
    .topbar-container {
      padding: 15px 10px;
      height: 12vh;
    }
  
    .topbar-logo-image {
      height: 70%;
    }
  
    .topbar-logo-container {
      display: flex;
      align-items: center;
    }
  
    .topbar-participants-number {
      font-weight: 600;
      font-size: 1.5rem;
    }
  
    .topbar-participants-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 20px;
      color: #ffffff;
      font-size: 1.2rem;
    }
  
    .topbar-profile-icon {
      display: none;
      height: 2.6vh;
      cursor: pointer;
    }
  
    .topbar-profile-greet {
      display: none;
      font-size: 1.3rem;
      color: #ffffff;
      width: 0;
    }
  
    .topbar-upper-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  
    .topbar-profile-container {
      width: 17vw;
    }
  
    .topbar-nav-container {
      display: none;
    }

    .topbar-logo-image {
      display: none;
    }
  }
  
  .topbar-menu-exclamation-icon {
    width: 18px;
    transition: transform 1s;
    animation: pulse 1.5s infinite;
    margin-left: 10px;
  }
  
  .topbar-menu-mobile {
    display: none;
  }
  
  .topbar-hamburguer {
    display: none;
  }
  
  .topbar-menu-bottom-container {
    display: flex;
    justify-content: space-between;
  }

  .topbar-menu-nav-bottom {
    padding: 6px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    border-radius: 20px;
    width: 45%;
    border: 3px solid black;
  }

  .topbar-menu-nav-bottom p {
    color: #FFFFFF;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 22px;
  }

  .topbar-menu-nav-bottom-logout {
    padding: 6px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid black;
    border-radius: 20px;
    width: 45%;
  }

  .topbar-menu-nav-bottom-logout p {
    color: black;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 22px;
  }
  
  .topbar-menu-red-arrow-icon {
    height: 16px;
    margin-right: 4px;
  }
  
  .topbar-menu-red-arrow-icon {
      height: 16px;
      margin-right: 4px;
  }
  
  .topbar-menu-nav-item-selected {
      color: black;
      font-size: 18px;
      font-weight: 600;
      padding: 6px 18px;
      border-radius: 10px;
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
  }
  
  .topbar-menu-nav-item {
      color: black;
      font-size: 18px;
      font-weight: 600;
      padding: 6px 18px;
      border-radius: 10px;
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @media only screen and (max-width: 600px) {
    .topbar-hamburguer {
      display: block;
    }
  
    .topbar-container {
      padding: 30px;
      height: 105px;
    }
  
    .topbar-participants-number {
      font-size: 1.6rem;
    }
  
    .topbar-participants-container {
      font-size: 1.1rem;
    }
  
    .topbar-profile-container {
      display: none;
    }
  
    .topbar-logo-container {
      height: 6vh;
    }
  
    .topbar-nav-container {
      display: none;
    }
  
    .topbar-menu-mobile {
      display: block;
      height: 100vh;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 130;
      background-color: #F2BD1C;
      transition: all 0.5s ease;
    }

    .logo-promo-mobile {
      width: 110px;
      margin: -20px 0px 32px 0px;
    }
  }
  