.popup-success-background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    justify-content: center;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 70;
}

.popup-success-container {
    width: 36vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    background-color: black;
    padding: 16px 10px 32px 10px;
    border-radius: 20px;
}

.popup-success-img {
    width: 100%;
    height: auto;
    z-index: 100;
    border-radius: 10px;
    background-color: black;
}

.popup-success-title {
    color: #FFFFFF;
    font-size: 2vw;
    font-weight: 600;
    z-index: 300;
    text-align: center;
    margin-bottom: 10px;
}

.popup-success-subtitle {
    color: #FFFFFF;
    font-size: 1.32vw;
    padding: 0px 32px;
    font-weight: 400;
    text-align: center;
}

.popup-success-subsubtitle {
    color: #FFFFFF;
    font-size: 1.12vw;
    font-weight: 300;
    text-align: center;
}

.popup-success-cta {
    margin-top: 24px;
    background-color: #F2BD1C;
    color: black;
    font-size: 1.6vw;
    font-family: 'Poppins';
    font-weight: 600;
    border-radius: 22px;
    text-align: center;
    border: 3px solid #FFFFFF;
    border-radius: 24px;
    padding: 6px 20px;
    cursor: pointer;
    transition: all 0.35s ease-out;
}

.popup-success-cta:hover {
    transform: scale(1.05);
    filter: brightness(1.1)
}

.popup-success-close {
    position: absolute;
    width: 18px;
    top: 12px;
    right: 12px;
    z-index: 400;
    cursor: pointer;
}

.popup-success-close:hover {
    filter: brightness(0.8)
}

.popup-success-cta-text {
    font-family: 'Poppins';
    font-weight: 600;
    text-transform: uppercase;
    color: #FFFFFF;
    font-size: 25px;
    margin-top: 20px;
    padding: 4px 20px;
}

@media (max-width: 600px) {
    .popup-success-container {
        width: 92vw;
        padding-top: 20px;
    }

    .popup-success-title {
        font-size: 28px;
        margin: 20px 0px 10px 0px;
    }

    .popup-success-subtitle {
        font-size: 22px;
    }

    .popup-success-subsubtitle {
        font-size: 18px;
        padding: 0px 30px;
    }

    .popup-success-cta {
        font-size: 25px;
        padding: 4px 20px;
    }

    .popup-success-close {
        position: absolute;
        width: 18px;
        z-index: 120;
        cursor: pointer;
        top: 12px;
        right: 15px;
    }
}