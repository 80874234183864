.admin-sidebar-container {
    width: 23vw;
    height: 100vh;
    position: fixed;
    background-color: black;
    padding: 28px;
}

.admin-sidebar-dropdown-title {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 4px;
    font-family: 'Rubik', sans-serif;
}

.admin-sidebar-dropdown-container {
    width: 100%;
    display: flex;
    padding: 1rem 0;
}

.admin-sidebar-dropdown p {
    font-size: 18px;
    font-family: 'Rubik', sans-serif;
}

.admin-sidebar-dropdown-arrow {
    width: 14px;
}

.admin-sidebar-add-container {
    display: flex;
    align-items: center;
    gap: 16px;
    height: 6vh;
    cursor: pointer;
    padding: 0px 18px;
    border-radius: 12px;
}

.admin-sidebar-add-container:hover {
    background-color: transparent;
}

.admin-sidebar-add-icon {
    width: 20px;
}

.admin-sidebar-line {
    width: 100%;
    height: 1px;
    background-color: #FFFFFF;
    margin: 8px 0px;
}

.admin-sidebar-links-container {
    margin-top: 12px;
    height: calc(62vh);
    overflow-y: auto;
}

.admin-sidebar-links-container::-webkit-scrollbar {
    display: none;
  }
  

.admin-sidebar-link-container {
    display: flex;
    align-items: center;
    height: 6.3vh;
    width: 100%;
    gap: 0.9vw;
    margin-bottom: 8px;
    border-radius: 12px;
    padding: 0px 18px;
    cursor: pointer;
}

.selected-link-container {
    border: 2px solid #F2BD1C;
}

.admin-sidebar-link-container:hover {
    background-color: transparent;
}

.admin-sidebar-link-text {
    font-size: 1.15vw;
    color: #FFFFFF;
    letter-spacing: 0.6px;
    font-family: 'Rubik', sans-serif;
}

.selected-link-text {
    font-weight: 600;
}

.admin-sidebar-logout-container {
    display: flex;
    align-items: center;
    height: 48px;
    gap: 20px;
    padding: 0px 10px;
    cursor: pointer;
    position: absolute;
    bottom: 32px
}

.admin-sidebar-link-icon {
    height: 48%
}