.promos-background {
    min-height: calc(100vh - 144px);
    width: 100vw;
    background-color:#EAEAEA;
    padding: 52px 100px
}

.vai-de-there-premiada-container {
    margin: 30px 0px;
}

.promos-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 30px;
}

.promo-card-description{
    font-size: 20px !important;
}

@media only screen and (max-width: 600px) {
    .promos-background {
        padding: 42px 20px
    }

    .promos-container {
        flex-direction: column;
        align-items: center;
        gap: 40px;
    }

    .vai-de-there-premiada-container {
        margin: 20px;
    }
}
