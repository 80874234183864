.signin-container {
    width: 100%;
    min-height: calc(100vh);
    background-color:#EAEAEA;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: calc(82px + 2vw) 0px 4vw 0px;
    display: flex;
    flex-direction: column;
    gap: 2.5vh;
}


.signin-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 24px;
    padding: 1.25vw 2.8vw;
    width: 60%;
    position: relative;
    border: 10px solid black;
    background-color: #F2BD1B;
}

.signin-form-row {
    display: flex;
    gap: 20px;
}

.signin-form-title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 2.4vw;
    text-align: center;
    color: black;
}

.signin-form-subtitle {
    font-size: 1.35vw;
    font-weight: 400;
    text-align: center;
    color: black;
    margin: -2px 0px 24px 0px;
}

.signin-form-invalid-cpf-border {
    padding: 4px;
    width: fit-content;
    height: fit-content;
    margin-bottom: 32px;
    border-radius: 22px;
    border: 3px solid black;
}

.signin-form-invalid-cpf-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    background-color:black;
    padding: 20px 56px;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.signin-form-invalid-cpf-warning-container {
    background-color:black;
    padding: 10px 23px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.signin-form-invalid-cpf-warning-text {
    text-transform: uppercase;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 1.2vw;
}

.signin-form-invalid-cpf-warning-img {
    width: 1.2vw;
    height: 1.2vw;
}

.signin-form-invalid-cpf-info {
    color: white;
    opacity: 0.95;
    font-size: 1.32vw;
    text-align: center;
    font-weight: 500;
}

.signin-form-invalid-cpf-button {
    font-family: 'Poppins';
    font-weight: 600;
    width: fit-content;
    font-size: 1.36vw;
    color: black;
    cursor: pointer;
    transition: all .2s ease-out;
    background-color: #F2BD1B;
    padding: 4px 32px;
    border-radius: 6px;
}

.signin-form-invalid-cpf-button:hover {
    filter: brightness(1.1);
    transform: scale(1.05);
}

.enter-here {
    font-weight: 500;
    cursor: pointer;
    background: 
    linear-gradient(#651c32 0 0) 
    var(--p, 0) 100% /var(--d, 0) 2px 
    no-repeat;
    transition: 0.3s, background-position 0s 0.3s;
    font-weight: 600;
    text-decoration: underline;
}

.enter-here:hover {
    --d: 100%;
    --p: 100%;
}

.signin-form-inputs-container {
    width: 100%;
}

.signin-form-button {
    background-color: black;
    border: 3px solid #FFFFFF;
    border-radius: 24px;
    padding: 10px 20px;
    font-size: 1.8vw;
    font-weight: 700;
    text-transform: uppercase;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.35s ease-out;
    margin-top: 20px;
    font-family: 'Poppins';
    width: 220px;
    height: 56px;
}

.signin-form-button:hover {
    transform: scale(1.05);
    filter: brightness(1.1)
}

.signin-form-checkbox-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.promo-kv-signin {
    display: none;
}

@media (max-width: 600px) {
    .signin-container {
        padding: 30% 0%;
        height: fit-content;
    }

    .signin-form-container {
        width: 90%;
        border: none;
        padding: 6vw;
    }
    
    .signin-form-row {
        flex-direction: column;
        gap: 6px;
    }

    .signin-form-title {
        font-size: 9vw;
    }

    .signin-form-subtitle {
        font-size: 5.2vw;
        margin-top: 0px;
    }

    .signin-form-button {
        font-size: 7vw;
    }

    .login-form-checkbox-container {
        font-size: 3vw;
    }

    .promo-kv-signin {
        display: flex;
        height: 15vh;
        margin: 2vh 0px 4vh 0px;
    }

    .signin-form-invalid-cpf-container {
        padding: 20px;
    }

    .signin-form-invalid-cpf-warning-container {
        padding: 6px 20px;
    }
    
    .signin-form-invalid-cpf-warning-text {
        font-size: 23px;
    }
    
    .signin-form-invalid-cpf-warning-img {
        width: 20px;
        height: 20px;
    }

    .signin-form-invalid-cpf-info {
        font-size: 23px;
    }

    .signin-form-invalid-cpf-button {
        font-size: 22px;
        padding: 6px 16px;
        border-radius: 8px;
    }
    
}
