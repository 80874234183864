.home-prizes-container {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 48px auto;
    width: 80%;
}

.home-prizes-title-container {
    margin-bottom: 32px;
}

.home-prizes-title-layer-2 {
    background-color: transparent;
    border-radius: 28px;
    padding: 4px;
    margin-bottom: 5vh;
}

.home-prizes-title-layer-1 {
    border: 3px solid black;
    background-color: transparent;
    border-radius: 22px;
    padding: 4px;
}

.home-prizes-title {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 7vw;
    color: #da291c;
    background-color: #FFFFFF;
    padding: 10px 120px;
    border-radius: 16px;
}

.home-prizes {
    padding: 24px 42px;
    border-radius: 20px;


}

.home-prizes-week-and-month {
    display: flex;
    gap: 56px;
}

.home-prize-container {
    width: 30vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-prize-type-container {
    padding: 8px 40px;
    border: 2px solid black;
    border-radius: 12px;
    justify-content: center;
    align-items: center;
    background: linear-gradient(#DA2A1C,#651C32);
    backdrop-filter: blur(8px); 
    box-shadow: 0px 13px 14px #00000029;
}

.home-prize-type-title {
    text-align: center;
    font-size: 1.8vw;
    font-weight: 600;
    color: #FFFFFF;
}

.home-prize-type-subtitle {
    color: #FFFFFF;
    text-align: center;
    font-size: 1.2vw;
}

.prize-banner {
    width: 100%;
    margin: 32px 0px;
}

.prize-slide {
    width: 30vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.prize-slide-img-container {
    width: 26vw;
    height: 16vw;
    background-color: #EBEBEB;
    border-radius: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.prize-img {
    height: 75%;
}

.final-prize-img {
    height: 320px;
}

.home-prize-final {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-prize-final-prize-container {
    background: linear-gradient(180deg, #340715 0%, #651C32 100%);
    padding: 6px 120px;
    border-radius: 8px;
    border: 1px solid black;
    backdrop-filter: blur(20px);
    box-shadow: 0px 13px 14px #00000029;
}

.home-prize-final-prize-title {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 1.8vw;
    color: #FFFFFF
}


.home-prize-final-prize-subtitle {
    font-size: 1.4vw;
    color: #FFFFFF;
    margin: 10px 0px;
}

@media only screen and (max-width: 600px) { 
    .home-prizes-container {
        background-color: black;
        margin-top: 30px;
        width: 100%;
    }

    .home-prizes-title-container {
        margin-top: 40px;
        margin-bottom: 0px;
    }

    .home-prizes {
        padding: 24px 42px;
        border-radius: 20px;
        border: none;
        background-color: black;
        background-image: none;
    }
    
    .home-prize-container {
        width: 80vw;
    }

    .home-prizes-week-and-month {
        flex-direction: column;
        gap: 0px;
        width: 100vw;
        align-items: center;
    }

    .home-prize-type-container {
        padding: 4px 8px;
        width: 60vw;
    }
    
    .home-prize-type-title {
        font-size: 20px;
    }
    
    .home-prize-type-subtitle {
        font-size: 16px;
    }

    .prize-banner {
        width: 100%;
        margin: 32px 0px;
    }
    
    .prize-slide {
        width: 80vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }    

    .prize-slide-img-container {
        background-color: transparent;
        width: fit-content;
        height: fit-content;
        border-radius: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .prize-img {
        height: 160px;
    }
    
    .final-prize-img {
        height: 200px;
    }
    
    .home-prize-final-prize-container {
        padding: 6px 32px;
    }

    .home-prize-final-prize-title {
        font-size: 24px
    }

    .home-prize-final-prize-subtitle {
        font-size: 18px;
    }

    .swiper-button-next {
        margin-right: 10px;
        background-size: 50% auto;
    }
    
    .swiper-button-prev {
        margin-left: 10px;
        background-size: 50% auto;
    }
}