.admin-page-see-address {
    text-decoration: underline;
    cursor: pointer;
}

.admin-page-see-address:hover {
    opacity: 0.8;
}

td select {
    padding: 1px 10px;
    border-radius: 4px;
}

td select option, td select {
    font-family: 'Rubik';
    font-size: 1vw;
}

.alterar-button{
    width:30%;
    border: 0;
    padding: 12px;
    border-radius: 20px;
    font-size: 1vw;
    color:white;
    border: 1px solid #D3D3D3;
    background-color: black;
    letter-spacing: 0.6px;
    margin-right: 15px;
    cursor: pointer;
}

.deletar-button{
    width:35%;
    border: 0;
    padding: 12px;
    border-radius: 20px;
    font-size: 1vw;
    border: 1px solid #D3D3D3;
    letter-spacing: 0.6px;
    background-color: red;
    color:white;
    cursor: pointer;
}

.paginate-button{
    border-radius: 5px;
    width:20px;
    height:20px;
    font-size: 1.5;
    color: black;
    background-color: white;
    margin-left: 3px;
    margin-right: 3px;
}

.paginate-button:disabled{
    border-radius: 5px;
    width:20px;
    height:20px;
    font-size: 1.5;
    color: grey;
    background-color: #D3D3D3;
    margin-left: 3px;
    margin-right: 3px;
}