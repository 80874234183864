.home-card-container {
    width: calc((100vw - 850px)/3);
    height: calc((100vw - 850px)/5);
    background-color: #F2BD1C;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    align-items: center;
    transition: .25s;
    cursor: pointer;
}

.home-card-container:hover {
    transform: scale(1.025);
    filter: brightness(1.2);
}

.home-card-icon {
    width: 25%;
}

.home-card-text {
    font-size: 1.4vw;
    text-align: center;
    font-weight: 500;
    color: black
}

#resultados-card .home-card-icon {
    width: 45%;
}


@media only screen and (max-width: 600px) { 
    .home-card-container {
        width: calc((100vw - 90px)/3);
        height: calc((100vw - 90px)/3);
        background-color: #FFFFFF;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        justify-content: center;
        align-items: center;
    }

    .home-card-icon {
        width: 40%;
    }

    .home-card-text {
        font-size: 4.2vw;
        text-align: center;
        font-weight: 500;
        color: black;
    }
}