/* style.css */
.buy-now-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 52px;
}

.buy-now-title {
    font-family: "Poppins";
    font-size: 1.5vw;
    text-align: center;
    font-weight: 600;
    margin-bottom: 10px;
}

.buy-now-text {
    font-family: "Poppins";
    font-size: 1.2vw;
    width: 41vw;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 400;
}

.buy-now-cta-container {
    border-radius: 12px;
    padding: 6px 20px;
    font-size: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.35s ease-out;
    background: black;
    box-shadow: 0px 13px 14px #00000029;
}

.buy-now-cta-container:hover {
    filter: brightness(1.2);
    transform: scale(1.05);
}

.buy-now-cta {
    font-family: 'Poppins';
    font-weight: 600;
    text-transform: uppercase;
    color: #FFFFFF;
}

@media only screen and (max-width: 600px) {
    .buy-now-container {
        margin-top: 8px;
    }

    .buy-now-title {
        font-size: 22px;
    }
    
    .buy-now-text {
        font-size: 18px;
        width: 78vw;
        margin-top: 5%;
    }
}
