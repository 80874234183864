.login-container {
    width: 100%;
    min-height: calc(100vh);
    background-color:#EAEAEA;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: calc(82px + 2vw) 0px 4vw 0px;
    display: flex;
    flex-direction: column;
    gap: 2.5vh;
}

.forgot-text{
    font-size: 1.2vw;
    color: black;
    text-align: center;
    text-decoration: underline;
    margin-bottom: 15px;
    display: inline-block;
    width: 100%;
    cursor: pointer;
}

.login-card {
    display: flex;
    border-radius: 24px;
    position: relative;
    border: 10px solid black;
    background-color: #F2BD1B;
    flex-wrap: wrap;
}

.login-left {
    padding: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid black;
}

.promo-kv {
    height: 25vw;
}

.login-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 34vw;
    height: fit-content;
    padding: 30px 0px;
    border-radius: 28px;
}

.login-form-row {
    display: flex;
    gap: 40px;
}

.login-form-input-container {
    display: flex;
    flex-direction: column;
    width: calc(50% - 25px);
}

.login-form-title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 2.4vw;
    text-align: center;
    color: black;
}

.login-form-subtitle {
    font-size: 1.35vw;
    font-weight: 400;
    text-align: center;
    color: black;
    margin: -2px 0px 24px 0px;
}

.create-account {
    font-weight: 500;
    cursor: pointer;
    background: 
    linear-gradient(black 0 0) 
    var(--p, 0) 100% /var(--d, 0) 2px 
    no-repeat;
    transition: 0.3s, background-position 0s 0.3s;
    font-weight: 600;
    text-decoration: underline;
}

.create-account:hover {
    --d: 100%;
    --p: 100%;
}

.login-form-inputs-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 10%;
    gap: 4px;
}

.signin-form-inputs-container {
    width: 100%;
}

.login-form-button {
    background-color: black;
    border: 3px solid #FFFFFF;
    border-radius: 24px;
    padding: 10px 20px;
    font-size: 1.8vw;
    font-weight: 700;
    text-transform: uppercase;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.35s ease-out;
    margin-top: 12px;
    font-family: 'Poppins';
    font-weight: 600;
    width: 150px;
    height: 56px;
}

.login-form-button:hover {
    transform: scale(1.05);
    filter: brightness(1.1)
}

.login-form-checkbox-container {
    display: flex;
    gap: 10px;
    font-size: 0.8rem;
    width: 100%;
    align-items: baseline;
    margin: 18px 0px;
}

.login-form-checkbox {
    width: 20px;
    cursor: pointer;
}

.login-form-hide-icon {
    width: 28px;
    cursor: pointer;
    opacity: 0.6;
    position: absolute;
    margin-top: 12px;
    margin-left: 70% 
}

.login-form-input-warning-text {
    height: 30px;
    color: #d0342c;
    display: flex;
    align-items: center;
    font-size: 1rem;
}

.black-label{
    color: black;
}

@media (max-width: 600px) {
    .login-container {
        padding: calc(82px + 10vw) 0px 15vw 0px;
    }

    .login-card {
        width: 90vw;
        height: fit-content;
        position: relative;
        border: none;
        flex-direction: column;
    }

    .promo-kv {
        height: 18vh;
    }

    .login-left {
        padding: 24px 0px 0px 0px;
        border-right: none;
    }

    .login-form-container {
        width: 90vw;
    }

    .login-form-title {
        font-size: 9vw;
    }

    .login-form-subtitle {
        font-size: 5.2vw;
        margin-top: 0px;
    }

    .login-form-button {
        font-size: 7vw;
    }

    .forgot-text{
        font-size: 5vw;
    }
}
