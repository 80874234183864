.prizes-background {
    min-height: calc(100vh - 100px);
    width: 100vw;
    background-color:#EAEAEA;
    padding: 52px 100px
}

.prizes-title-container {
    background-color: black;
    padding: 0.6vw 2vw;
    color: #FFFFFF;
    border-radius: 15px;
    z-index: 4;
    width: fit-content;
    margin-bottom: 4vh;
}

.prizes-title {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 2vw;
    letter-spacing: 0.6px;
    z-index: 4;
}

.toby-tomada {
    position: absolute;
    width: 390px;
    top: 196px;
    left: -172px;
}
.prizes-to-withdraw-title {
    font-size: 42px;
    font-weight: 500;
    margin-bottom: 10px;
}

.prizes-to-withdraw-subtitle {
    font-size: 1.8rem;
}

.prizes-to-withdraw-item {
    margin: 8px 0px 8px 20px;
    font-size: 1.4rem;
}

.item-color {
    color: #da291c;
    font-weight: 700;
    margin-right: 10px;
}

.item-verb {
    letter-spacing: 0.7px;
    font-weight: 500;
}

#pointer-text{
    cursor: pointer;
}

.download-color{
    color: black;
}

.prizes-to-withdraw-disclaimer {
    font-size: 1.8rem;
    margin-bottom: 60px;
}

.sub-text{
    text-decoration: underline;
    color: #da291c;
    cursor: pointer;
}

.prizes-to-withdraw-container {
    display: inline-flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 60px;
}

.card-prize-container {
    background-color: #651c32;
    display: inline-block;
    padding: 18px 30px;
    border-radius: 20px;
    color: #FFFFFF;
    cursor: pointer;
    font-size: 1.1rem;
    transition: all 0.1s linear;
    font-weight: 500;
}

.card-prize-container:hover {
    background-color: rgb(101, 28, 50, 0.9);
    transform: scale(1.01)
}

.card-prize-prize {
    font-size: 1.4rem;
    letter-spacing: 0.7px;
}

.prizes-history-title {
    font-size: 2rem;
    font-weight: 500;
}

.prizes-table {
    border-radius: 20px;
    background-color: #FFFFFF;
    margin-top: 20px;
    width: 70vw;
    border-spacing: 0;
}

.prizes-table th {
    padding: 20px 32px;
    font-size: 1.2vw;
    font-weight: 600;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
}

.prizes-table td {
    padding: 18px 38px;
    text-align: center;
}

.prizes-table tr {
    border-radius: 10px;
    font-size: 1.025vw;
    letter-spacing: 0.5px;
}

.prizes-table tbody tr:hover {
    background-color: #fbfbfb;
}

.prizes-table-luck-number {
    letter-spacing: 0.8px;
    font-size: 1.06rem;
}

.prizes-table td:first-child {
    border-top-left-radius: 10px; 
    border-bottom-left-radius: 10px;
}
.prizes-table td:last-child {
    border-bottom-right-radius: 10px; 
    border-top-right-radius: 10px; 
}

.winner-prize{
    color: #da291c;
    font-weight: 600;
    margin-left: 5%;

}

@media only screen and (max-width: 600px) {
    .prizes-background {
        padding: 42px 20px;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .prizes-title-container {
        background-color: #651c32;
        padding: 0.6vw 2vw;
        color: #FFFFFF;
        border-radius: 15px;
        z-index: 4;
        width: fit-content;
        margin-bottom: 4vh;
    }

    .prizes-title {
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 10vw;
        letter-spacing: 0.6px;
        z-index: 4;
    }

    .prizes-title-mobile {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }

    .card-prize-container {
        padding: 12px 20px;
        font-size: 1rem;
        width: 80vw;
        text-align: center;
    }
    
    .toby-tomada {
        display: none;
    }

    .prizes-to-withdraw-title {
        font-size: 24px;
        font-weight: 600;
        width: 100%;
        text-align: center;
    }

    .prizes-to-withdraw-subtitle {
        font-size: 1.5rem;
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
    }

    .prizes-to-withdraw-item {
        align-self: flex-start;
    }
    
    .prizes-to-withdraw-disclaimer {
        width: 100%;
        margin-top: 20px;
        text-align: center;
    }

    .prizes-history-title {
        font-size: 1.6rem
    }

    .prizes-history-subtitle {
        font-size: 1.5rem;
    }

    .prizes-table th {
        padding: 18px 16px;
        font-size: 4.8vw;
    }
    
    .prizes-table td {
        padding: 12px 16px;
        text-align: center;
        font-size: 4vw;
    }
}